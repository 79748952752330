

export default {
  name: 'PageNavbar',

  computed: {
    can_go_back: ->
      @$store.state.layout.path != '/'

    supplier: ->
      @$store.state.catalog.supplier

    back_target: ->
      @$store.state.layout.page_nav_back_target

    title: ->
      @$store.state.layout.page_nav_title
  },

  methods: {
    go_back: ->
      if @back_target then @$router.push(@back_target) else @$router.back()
  }
}
