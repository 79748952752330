import _ from 'underscore'
import { as_json } from '@/lib/helpers'
import { api_for } from '@/services/helper'

import Storage from '@/lib/storage'
store = new Storage({ key: 'account-v1' })

default_state = -> {
  addresses: [],
  current: null,
  errors: null,
  loading: false
}

state = default_state()

getters = {
  error: (state) -> state.error if !!state.error

  loading: (state) -> state.loading

  as_json: (state) -> as_json(state.current)

  form_data: (state, getters, rootState, rootGetters) ->
    if rootGetters['application/agency'] then getters['agency_user_fields'] else getters['market_user_fields']

  agency_user_fields: (state) ->
    {
      login: state.current.login
      first_name: state.current.first_name
      last_name: state.current.last_name
      profile: {
        sms_marketing: state.current.profile.sms_marketing
        terms: state.current.profile.terms
      }
    }

  market_user_fields: (state) ->
    {
      login: state.current.login
      first_name: state.current.first_name
      last_name: state.current.last_name
      profile: {
        sms_marketing: state.current.profile.sms_marketing
        terms: state.current.profile.terms
      }
    }

  logged_in: (state) -> !!state.current?.id

  as_payload: (state) -> _.omit(as_json(state.current), ['addresses', 'full_name', 'push_subscriptions'])

  debtor_registration_required: (state) ->
    !state.current.id_number &&
    !state.current.business_address_1

  find_debtorcard: (state) -> (supplier_id) ->
    state.current?.debtor_cards?.find (card) -> card.supplier_id == supplier_id

  find_active_debtorcard: (state, getters) -> (supplier_id) ->
    getters['find_debtorcard'](supplier_id)?.status == 'accepted'

  sendinblue_id: (state) ->
    state.current?.sendinblue_id
}

actions = {
  sign_in: ({ commit, dispatch, rootState }, credentials) ->
    commit('loading')
    commit('flush_errors')
    api = api_for(rootState.application)
    api.signIn('user', { body: credentials })
    .then (response) ->
      if response.status == 'ok'
        commit('set_addresses', response.account.addresses)
        commit('set', _.omit(response.account, 'addresses'))
      else
        commit('errors', response.messages)
    .catch (error) ->
      console.error('account.sign_in', error)
    .finally -> commit('loaded')

  sign_up: ({ commit, dispatch, rootState }, user_fields) ->
    commit('loading')
    commit('flush_errors')
    api = api_for(rootState.application, {})
    api.signUp('user', { body: user_fields })
    .then (response) ->
      if response.status == 'ok'
        commit('set_addresses', response.account.addresses)
        commit('set', _.omit(response.account, 'addresses'))
      else
        commit('errors', response.messages)
    .catch (error) ->
      console.error('account.sign_up', error)
    .finally -> commit('loaded')

  sign_out: ({ commit, dispatch }) ->
    commit('set', default_state())

  update: ({ commit, rootState, state }, form_data) ->
    commit('loading')
    commit('flush_errors')
    api_for(rootState.application, {}).updateAccount 'user', state.current.id, { body: form_data }
    .then (response) ->
      if response.status == 'ok'
        commit('set_addresses', response.account.addresses)
        commit('set', _.omit(response.account, 'addresses'))
      else
        commit('errors', response.messages)
    .catch (error) ->
      console.error('account.update', error)
    .finally ->
      commit('loaded')

  create_address: ({ commit, rootState, state }, address) ->
    commit('loading')
    api = api_for(rootState.application, {})
    api.newUserAddress state.current.id, { body: address }
    .then (response) ->
      if response.status == 'ok'
        commit('add_address', response.resource)
        response.resource
    .catch (error) ->
      commit('error', error)
    .finally -> commit('loaded')

  fetch_addresses: ({ commit, state, rootState }) ->
    commit('loading')
    api = api_for(rootState.application, {})
    api.fetchUserAddresses state.current.id
    .then (response) ->
      if response.status == 'ok'
        commit('set_addresses', response.resources)
    .catch (error) ->
      console.error('account.fetch_addresses', error)
    .finally -> commit('loaded')

  remove_address: ({ commit, state, rootState }, id) ->
    api = api_for(rootState.application, {})
    api.removeUserAddress state.current.id, id
    .then (response) ->
      if response.status == 'ok'
        commit('set_addresses', response.resources)
    .catch (error) ->
      console.error('Account.remove_address.catch', error)
    .finally -> commit('loaded')

  forgot_password: ({ commit, rootState }, login) ->
    api = api_for(rootState.application, {})
    api.forgotPassword('user', { body: { login: login } })
    .then (response) -> response
    .catch (error) ->
      console.error('account.forgot_password.catch', error)

  validate_password_reset_token: ({ commit, rootState }, credentials) ->
    api = api_for(rootState.application, {})
    api.validatePasswordResetToken('user', { body: credentials })
    .then (response) -> response
    .catch (error) ->
      console.error('account.validate_password_reset_token.catch', error)

  reset_password: ({ rootState }, credentials) ->
    api = api_for(rootState.application, {})
    api.resetPassword('user', { body: { token_id: credentials.token_id, password: credentials.password } })
    .then (response) -> response
    .catch (error) ->
      console.error('account.reset_password.catch', error)

  request_debtorcard: ({ commit, state, rootState }, supplier_id) ->
    api = api_for(rootState.application, {})
    api.newDebtorRegistration(state.current.id, { body: { supplier_id: supplier_id } })
    .then (response) ->
      if response.status == 'ok'
        commit('set_addresses', response.account.addresses)
        commit('set', _.omit(response.account, 'addresses'))
      else
        commit('errors', response.messages)
    .catch (error) ->
      console.error('account.request_debtorcard.catch', error)
}

mutations = {
  restore: (state) ->
    cached = store.read()
    if cached and cached.current
      state.current = cached.current
      state.addresses = cached.addresses || []
    else
      state = default_state()

  set: (state, account) ->
    state.current = account
    store.write({ current: state.current, addresses: state.addresses })

  loading: (state) ->
    state.loading = true

  loaded: (state) ->
    state.loading = false

  errors: (state, messages) ->
    state.errors = messages

  flush_errors: (state) ->
    state.errors = null

  add_address: (state, address) ->
    existing_entry = state.addresses.find (item) -> item.id == address.id
    if existing_entry? then existing_entry = address else state.addresses.push(address)
    store.write({ current: state.current, addresses: state.addresses })

  set_addresses: (state, addresses) ->
    state.addresses = addresses || []
    store.write({ current: state.current, addresses: state.addresses })
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
