default_alert = ->
  {
    show: false,
    text: '',
    type: 'error'
  }

default_state = -> {
  adding_to_cart: false
  alert: default_alert()
  offline_alert: false
  current_offer: null
  account_required_modal: false
  order_timeout_modal: false
  sidepanel: false
  debtorcard_detail: {
    supplier: null
    card: null
  },
  page_nav_title: ''
  page_nav_back_target: null
  path: '/'
}

state = default_state()

actions = {
  notify: ({ commit }, text) ->
    commit('alert', { type: 'success', show: true, text: text })
    setTimeout ->
      commit('alert', default_alert())
    , 5000
}

mutations = {
  alert: (state, alert) ->
    state.alert = alert

  show_offline_alert: (state, show) ->
    state.offline_alert = show

  show_add_to_cart: (state, options) ->
    state.current_offer = options.offer
    state.adding_to_cart = true

  dismiss_add_to_cart: (state) ->
    state.adding_to_cart = false
    state.current_offer = {}

  show_account_required: (state, show) ->
    state.account_required_modal = show

  sidepanel: (state, open) ->
    state.sidepanel = open

  show_debtorcard_topup_detail: (state, supplier_and_card) ->
    if !!supplier_and_card.supplier.id && !!supplier_and_card.card.supplier_id
      state.debtorcard_detail = supplier_and_card

  hide_debtorcard_topup_detail: (state) ->
    state.debtorcard_detail = { supplier: null, card: null }

  order_timeout_modal: (state, show) ->
    state.order_timeout_modal = show

  page_nav: (state, options={title: 'page nav title', back_target: null}) ->
    state.page_nav_title = options.title
    state.page_nav_back_target = options.back_target

  path: (state, path_string) ->
    state.path = path_string

}

export default {
  namespaced: true
  state
  actions
  mutations
}
