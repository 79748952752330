/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from '../ApiClient';
import {CartItems} from './CartItems';

/**
 * The CartValidationResponseValidation model module.
 * @module model/CartValidationResponseValidation
 * @version v1
 */
export class CartValidationResponseValidation {
  /**
   * Constructs a new <code>CartValidationResponseValidation</code>.
   * @alias module:model/CartValidationResponseValidation
   * @class
   */
  constructor() {
  }

  /**
   * Constructs a <code>CartValidationResponseValidation</code> from a plain JavaScript object, optionally creating a new instance.
   * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
   * @param {Object} data The plain JavaScript object bearing properties of interest.
   * @param {module:model/CartValidationResponseValidation} obj Optional instance to populate.
   * @return {module:model/CartValidationResponseValidation} The populated <code>CartValidationResponseValidation</code> instance.
   */
  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new CartValidationResponseValidation();
      if (data.hasOwnProperty('updated'))
        obj.updated = ApiClient.convertToType(data['updated'], 'Boolean');
      if (data.hasOwnProperty('out_of_stock'))
        obj.out_of_stock = ApiClient.convertToType(data['out_of_stock'], 'Boolean');
      if (data.hasOwnProperty('total_error'))
        obj.total_error = ApiClient.convertToType(data['total_error'], 'String');
      if (data.hasOwnProperty('dispatch_error'))
        obj.dispatch_error = ApiClient.convertToType(data['dispatch_error'], 'String');
      if (data.hasOwnProperty('items'))
        obj.items = CartItems.constructFromObject(data['items']);
      if (data.hasOwnProperty('credit_in_cents'))
        obj.credit_in_cents = ApiClient.convertToType(data['credit_in_cents'], 'Number');
      if (data.hasOwnProperty('discount_code'))
        obj.discount_code = ApiClient.convertToType(data['discount_code'], 'String');
      if (data.hasOwnProperty('discount_codes_enabled'))
        obj.discount_codes_enabled = ApiClient.convertToType(data['discount_codes_enabled'], 'Boolean');
      if (data.hasOwnProperty('discount_code_error'))
        obj.discount_code_error = ApiClient.convertToType(data['discount_code_error'], 'String');
      if (data.hasOwnProperty('discount_in_cents'))
        obj.discount_in_cents = ApiClient.convertToType(data['discount_in_cents'], 'Number');
      if (data.hasOwnProperty('subtotal_in_cents'))
        obj.subtotal_in_cents = ApiClient.convertToType(data['subtotal_in_cents'], 'Number');
      if (data.hasOwnProperty('shipping_cost_in_cents'))
        obj.shipping_cost_in_cents = ApiClient.convertToType(data['shipping_cost_in_cents'], 'Number');
      if (data.hasOwnProperty('stokvel'))
        obj.stokvel = ApiClient.convertToType(data['stokvel'], 'Boolean');
      if (data.hasOwnProperty('stokvel_errors'))
        obj.stokvel_errors = ApiClient.convertToType(data['stokvel_errors'], ['String']);
      if (data.hasOwnProperty('total_in_cents'))
        obj.total_in_cents = ApiClient.convertToType(data['total_in_cents'], 'Number');
    }
    return obj;
  }
}

/**
 * @member {Boolean} updated
 */
CartValidationResponseValidation.prototype.updated = undefined;

/**
 * @member {Boolean} out_of_stock
 */
CartValidationResponseValidation.prototype.out_of_stock = undefined;

/**
 * @member {String} total_error
 */
CartValidationResponseValidation.prototype.total_error = undefined;

/**
 * @member {String} dispatch_error
 */
CartValidationResponseValidation.prototype.dispatch_error = undefined;

/**
 * @member {module:model/CartItems} items
 */
CartValidationResponseValidation.prototype.items = undefined;

/**
 * @member {Number} credit_in_cents
 */
CartValidationResponseValidation.prototype.credit_in_cents = undefined;

/**
 * @member {String} discount_code
 */
CartValidationResponseValidation.prototype.discount_code = undefined;

/**
 * @member {Boolean} discount_codes_enabled
 */
CartValidationResponseValidation.prototype.discount_codes_enabled = undefined;

/**
 * @member {String} discount_code_error
 */
CartValidationResponseValidation.prototype.discount_code_error = undefined;

/**
 * @member {Number} discount_in_cents
 */
CartValidationResponseValidation.prototype.discount_in_cents = undefined;

/**
 * @member {Number} subtotal_in_cents
 */
CartValidationResponseValidation.prototype.subtotal_in_cents = undefined;

/**
 * @member {Number} shipping_cost_in_cents
 */
CartValidationResponseValidation.prototype.shipping_cost_in_cents = undefined;

/**
 * @member {Boolean} stokvel
 */
CartValidationResponseValidation.prototype.stokvel = undefined;

/**
 * @member {Array.<String>} stokvel_errors
 */
CartValidationResponseValidation.prototype.stokvel_errors = undefined;

/**
 * @member {Number} total_in_cents
 */
CartValidationResponseValidation.prototype.total_in_cents = undefined;

