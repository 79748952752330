<template>
  <v-dialog v-model='isVisible' persistent max-width='500' class='ma-4' @click.self="cancel">
    <v-card cy-confirm-modal class="pa-4 pt-5">
      <div class="r---circle r---circle-red mx-auto mb-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-exclamation-lg" viewBox="0 0 16 16">
          <path d="M7.005 3.1a1 1 0 1 1 1.99 0l-.388 6.35a.61.61 0 0 1-1.214 0L7.005 3.1ZM7 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0Z"/>
        </svg>
      </div>
      <div class='mb-4 r---text-size-1 text-center'>
        {{ message }}
      </div>
      <div class='r---button-group flex-row'>
        <v-btn block class='r---button-small r---button-delete' @click='cancel'>
          {{ cancelText }}
        </v-btn>
        <v-btn block class='r---button-small r---button-primary' @click='confirm'>
          {{ confirmText }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      resolvePromise: null,
      rejectPromise: null,
      title: '',
      message: '',
      confirmText: 'OK',
      cancelText: 'Cancel'
    }
  },

  methods: {
    show(options = {}) {
      this.isVisible = true
      this.title = options.title || 'Confirm'
      this.message = options.message || 'Are you sure?'
      this.confirmText = options.confirmText || 'OK'
      this.cancelText = options.cancelText || 'Cancel'

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },
    confirm() {
      this.isVisible = false
      this.resolvePromise(true)
    },
    cancel() {
      this.isVisible = false
      this.resolvePromise(false)
    }
  }
}
</script>

<style scoped></style>
