import { as_json } from '@/lib/helpers'
import { DateTime } from 'luxon'
import _ from 'underscore'
import { api_for } from '../../services/helper'
import { v4 as uuidv4 } from 'uuid'

ShopitApiV1 = require('@/services/market_api_v1')
shopit_cart_api = new ShopitApiV1.ApiClient()
shopit_api = new ShopitApiV1.DefaultApi(shopit_cart_api)

import Storage from '@/lib/storage'
store = new Storage({ key: 'cart-v1' })

default_state = ->
  {
    comparisons: [],
    id: null,
    items: [],
    loading: false,
    location: {},
    proxy_order_id: null
  }

state = default_state()

getters = {
  build_checkout: (state, getters, rootState, rootGetters) ->
    in_stock_items = state.items.filter (item) -> !item.offer.out_of_stock_at
    {
      items: in_stock_items,
      subtotal_in_cents: total_price_in_cents_for(state.items),
      supplier: as_json(rootGetters['suppliers/current']),
      total_in_cents: total_price_in_cents_for(state.items)
    }

  subtotal_in_cents: (state, getters) ->
    total_price_in_cents_for(getters.in_stock_items)

  total: (state) ->
    (total_price_in_cents_for(state.items) / 100).toFixed(2)

  in_stock_items: (state) ->
    state.items.filter (a,b) -> !a.offer.out_of_stock_at

  item_count: (state, getters) ->
    getters.in_stock_items?.length || 0

  items_by_supplier: (state, getters) ->
    suppliers = getters.suppliers
    for supplier in suppliers
      supplier.items = state.items.filter (item) -> item.offer.supplier.id == supplier.id
      supplier.total = (total_price_in_cents_for(supplier.items) / 100).toFixed(2)
    suppliers

  suppliers: (state) ->
    suppliers = {}
    for item in as_json(state.items)
      suppliers[item['offer']['supplier']['id']] = item['offer']['supplier']
    (v for k, v of suppliers)

  offer_qty: (state, getters) -> (offer_id) ->
    return 0 if !offer_id

    item = getters.cart_item(offer_id)
    item?.qty || 0

  cart_item: (state) -> (offer_id) ->
    state.items.find (item) -> item.offer.id == offer_id

  formatted_offer_qty: (state, getters) -> (offer_id) ->
    return '' if !offer_id
    item = getters.cart_item(offer_id)
    return '' if !item or item.qty == 0

    return item.qty unless item.offer.per_kg

    converted_qty = item.qty / item.offer.per_kg_conversion_rate
    formatted_converted_qty = if item.offer.per_kg_symbol.toLowerCase() in ['kg', 'l'] then converted_qty.toFixed(3) else converted_qty.toFixed(0)
    "#{formatted_converted_qty} #{item.offer.per_kg_symbol}"

  offer_total_in_cents: (state) -> (offer_id) ->
    item = state.items.find (item) -> item.offer.id == offer_id
    if item then Number(item.qty) * Number(item.offer.price_in_cents) else 0

  is_editing_order: (state) ->
    Boolean(state.proxy_order_id)
}

actions = {
  add_item: ({ commit, dispatch, getters, rootGetters }, item) ->
    current_qty = getters.offer_qty(item.offer.id)
    if current_qty > 0
      commit('set_item_qty', { offer_id: item.offer.id, qty: item.qty })
    else
      commit('add_item', as_json(item))
    unit_price = (item.offer.price_in_cents / 100).toFixed(2)
    tracking_properties = {
      contents: [ id: item.offer.sellable.barcode, quantity: item.qty, value: item.qty * unit_price ],
      currency: rootGetters['application/currency_iso'],
      value: item.qty * unit_price
    }
    fbq('track', 'AddToCart', tracking_properties)
    dispatch('cart/sync', null, { root: true })

  remove_item: ({ commit, dispatch }, offer_id) ->
    commit('remove_item', offer_id)
    dispatch('cart/sync', null, { root: true })

  set_item_qty: ({ commit, dispatch }, params = { offer_id: null, qty: 1 }) ->
    commit('set_item_qty', { offer_id: params.offer_id, qty: params.qty })
    # dispatch('analytics/sendinblue_cart_updated', null, { root: true })
    dispatch('cart/sync', null, { root: true })

  clear: ({ commit, dispatch }) ->
    commit('clear')
    # dispatch('analytics/sendinblue_cart_deleted', null, { root: true })
    dispatch('cart/sync', null, { root: true })

  reset: ({ commit }, items) ->
    commit('clear')
    commit('add_item', item) for item in items

  fetch_comparisons: ({ commit, state, rootState }) ->
    commit 'loading', true
    api_for(rootState.application).cartCompare({
      id: state.id,
      lat: state.location?.latitude,
      lon: state.location?.longitude
    })
    .then (comparisons) ->
      commit 'set_comparisons', comparisons
    .catch (response) ->
      commit 'error', response
    .finally ->
      commit 'loading', false

  fetch_comparisons_debug: ({ commit, state, rootState }) ->
    commit 'loading', true
    api_for(rootState.application).cartCompareDebug({ id: state.id })
    .then (comparisons) -> comparisons
    .catch (response) ->
      commit 'error', response
    .finally ->
      commit 'loading', false

  sync: ({ commit, state, rootState }) ->
    return if !!rootState.application.features.agency

    commit('set_id', state.id || uuidv4())
    api_for(rootState.application).cartSync({
      body: {
        items: as_json(state.items)
        id: state.id
      }
    })

  restore: ({ commit }) ->
    state = default_state()
    state.id = store.read()?.id || null
    state.items = store.read()?.items || []
    commit('restore_state', state)
}

mutations = {
  restore_state: (state, new_state) ->
    state.id = new_state.id
    state.items = new_state.items

  set_id: (state, id) ->
    state.id = id
    store.write(state)

  add_item: (state, item) ->
    item.qty = parse_to_qty(item.qty)
    state.items.push(item)
    store.write(state)

  set_item_qty: (state, options) ->
    item = state.items.find (item) -> item.offer.id == options.offer_id
    item.qty = parse_to_qty(options.qty)
    store.write(state)

  remove_item: (state, offer_id) ->
    state.items = state.items.filter (item) -> item.offer.id != offer_id
    store.write(state)

  clear: (state) ->
    state.items = []
    state.requesting_checkout = false
    state.loading = false
    state.location = {}
    state.checkout_config = {}
    state.comparisons = []
    state.id = null
    localStorage.removeItem('cart-v1-config')
    store.clear()

  loading: (state, loading) ->
    state.loading = loading

  requesting_checkout: (state, boolean) ->
    state.requesting_checkout = boolean

  set_comparisons: (state, comparisons) ->
    state.comparisons.splice(0, state.comparisons.length)
    state.comparisons = comparisons

  set_location: (state, location) ->
    state.location = location

  start_order_proxy: (state, order_id) ->
    state.proxy_order_id = order_id

  stop_order_proxy: (state) ->
    state.proxy_order_id = null

  error: (state, error) ->
    state.error = error
}

total_price_in_cents_for = (items) ->
  _.reduce(items, sum_of_item_totals, 0)

parse_to_qty = (qty) ->
  parseFloat(Number(qty).toFixed(5))

sum_of_item_totals = (memo, value, index, list) ->
  memo + Number(value.offer.price_in_cents) * parseFloat(Number(value.qty).toFixed(5))


export default {
  namespaced: true
  state
  actions
  mutations
  getters
}
