/*
 * shopit-api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.41
 *
 * Do not edit the class manually.
 *
 */
import {ApiClient} from "../ApiClient";
import {Address} from '../model/Address';
import {Addresses} from '../model/Addresses';
import {CartComparison} from '../model/CartComparison';
import {CartItem} from '../model/CartItem';
import {CartValidationRequest} from '../model/CartValidationRequest';
import {CartValidationResponse} from '../model/CartValidationResponse';
import {Category} from '../model/Category';
import {ClientConfig} from '../model/ClientConfig';
import {CreateAddressResponse} from '../model/CreateAddressResponse';
import {ForgotPasswordResponse} from '../model/ForgotPasswordResponse';
import {InlineResponse200} from '../model/InlineResponse200';
import {InlineResponse2001} from '../model/InlineResponse2001';
import {InlineResponse2002} from '../model/InlineResponse2002';
import {Leaflets} from '../model/Leaflets';
import {ManagerApiV1ImportsCreateSuccessResponse} from '../model/ManagerApiV1ImportsCreateSuccessResponse';
import {ManagerApiV1LeafletsCreateSuccessResponse} from '../model/ManagerApiV1LeafletsCreateSuccessResponse';
import {NewDebtorRegistrationPayload} from '../model/NewDebtorRegistrationPayload';
import {OfferSearchResponse} from '../model/OfferSearchResponse';
import {Offers} from '../model/Offers';
import {OffersValidationResponse} from '../model/OffersValidationResponse';
import {Order} from '../model/Order';
import {OrderModification} from '../model/OrderModification';
import {OrderModificationsValidationResponse} from '../model/OrderModificationsValidationResponse';
import {OrderReview} from '../model/OrderReview';
import {Orders} from '../model/Orders';
import {OrdersIndex} from '../model/OrdersIndex';
import {ProductShowJson} from '../model/ProductShowJson';
import {ProductShowOffersJson} from '../model/ProductShowOffersJson';
import {Products} from '../model/Products';
import {PromotionsActive} from '../model/PromotionsActive';
import {RegisterOrderRequest} from '../model/RegisterOrderRequest';
import {RegisterOrderResponse} from '../model/RegisterOrderResponse';
import {ResetPasswordPayload} from '../model/ResetPasswordPayload';
import {ResetPasswordResponse} from '../model/ResetPasswordResponse';
import {RootCategories} from '../model/RootCategories';
import {ScopeValidateTokenBody} from '../model/ScopeValidateTokenBody';
import {SignInPayload} from '../model/SignInPayload';
import {SignInResponse} from '../model/SignInResponse';
import {SignUpPayload} from '../model/SignUpPayload';
import {SignUpResponse} from '../model/SignUpResponse';
import {StokvelActive} from '../model/StokvelActive';
import {SupplierTimeslots} from '../model/SupplierTimeslots';
import {Suppliers} from '../model/Suppliers';
import {Transaction} from '../model/Transaction';
import {UserUpdateResponse} from '../model/UserUpdateResponse';
import {V1LeafletsBody} from '../model/V1LeafletsBody';
import {ValidatePasswordResetToken} from '../model/ValidatePasswordResetToken';

/**
* Default service.
* @module api/DefaultApi
* @version v1
*/
export class DefaultApi {

    /**
    * Constructs a new DefaultApi. 
    * @alias module:api/DefaultApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instanc
    e} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * import
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    apiV1MigrationPostWithHttpInfo() {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/v1/migration', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * import
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    apiV1MigrationPost() {
      return this.apiV1MigrationPostWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * finalize_transaction
     * @param {String} order_id 
     * @param {String} transaction_id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.PAY_REQUEST_ID 
     * @param {String} opts.TRANSACTION_STATUS 
     * @param {String} opts.CHECKSUM 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    apiV1OrdersOrderIdTransactionsTransactionIdFinalizePostWithHttpInfo(order_id, transaction_id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'order_id' is set
      if (order_id === undefined || order_id === null) {
        throw new Error("Missing the required parameter 'order_id' when calling apiV1OrdersOrderIdTransactionsTransactionIdFinalizePost");
      }
      // verify the required parameter 'transaction_id' is set
      if (transaction_id === undefined || transaction_id === null) {
        throw new Error("Missing the required parameter 'transaction_id' when calling apiV1OrdersOrderIdTransactionsTransactionIdFinalizePost");
      }

      let pathParams = {
        'order_id': order_id,'transaction_id': transaction_id
      };
      let queryParams = {
        'PAY_REQUEST_ID': opts['PAY_REQUEST_ID'],'TRANSACTION_STATUS': opts['TRANSACTION_STATUS'],'CHECKSUM': opts['CHECKSUM']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/v1/orders/{order_id}/transactions/{transaction_id}/finalize', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * finalize_transaction
     * @param {<&vendorExtensions.x-jsdoc-type>} order_id 
     * @param {<&vendorExtensions.x-jsdoc-type>} transaction_id 
     * @param {Object} opts Optional parameters
     * @param {String} opts.PAY_REQUEST_ID 
     * @param {String} opts.TRANSACTION_STATUS 
     * @param {String} opts.CHECKSUM 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    apiV1OrdersOrderIdTransactionsTransactionIdFinalizePost(order_id, transaction_id, opts) {
      return this.apiV1OrdersOrderIdTransactionsTransactionIdFinalizePostWithHttpInfo(order_id, transaction_id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * completes the job
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2002} and HTTP response
     */
    apiV1SupplierOrderPackIdCompletePostWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiV1SupplierOrderPackIdCompletePost");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2002;

      return this.apiClient.callApi(
        '/api/v1/supplier_order_pack/{id}/complete', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * completes the job
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2002}
     */
    apiV1SupplierOrderPackIdCompletePost(id) {
      return this.apiV1SupplierOrderPackIdCompletePostWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * stock shorts the job and order
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2002} and HTTP response
     */
    apiV1SupplierOrderPackIdDisputePostWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiV1SupplierOrderPackIdDisputePost");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = InlineResponse2002;

      return this.apiClient.callApi(
        '/api/v1/supplier_order_pack/{id}/dispute', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * stock shorts the job and order
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2002}
     */
    apiV1SupplierOrderPackIdDisputePost(id) {
      return this.apiV1SupplierOrderPackIdDisputePostWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * issue
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    apiV1SupplierOrderPackIdIssuePostWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiV1SupplierOrderPackIdIssuePost");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/x-www-form-urlencoded'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;

      return this.apiClient.callApi(
        '/api/v1/supplier_order_pack/{id}/issue', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * issue
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    apiV1SupplierOrderPackIdIssuePost(id) {
      return this.apiV1SupplierOrderPackIdIssuePostWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * issue
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse2001} and HTTP response
     */
    apiV1SupplierOrderProcessIdIssuePostWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling apiV1SupplierOrderProcessIdIssuePost");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/x-www-form-urlencoded'];
      let accepts = ['application/json'];
      let returnType = InlineResponse2001;

      return this.apiClient.callApi(
        '/api/v1/supplier_order_process/{id}/issue', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * issue
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse2001}
     */
    apiV1SupplierOrderProcessIdIssuePost(id) {
      return this.apiV1SupplierOrderProcessIdIssuePostWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * cancel_order
     * @param {String} order_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    cancelOrderWithHttpInfo(order_id) {
      
      let postBody = null;
      // verify the required parameter 'order_id' is set
      if (order_id === undefined || order_id === null) {
        throw new Error("Missing the required parameter 'order_id' when calling cancelOrder");
      }

      let pathParams = {
        'order_id': order_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/api/v1/orders/{order_id}/cancel', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * cancel_order
     * @param {<&vendorExtensions.x-jsdoc-type>} order_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    cancelOrder(order_id) {
      return this.cancelOrderWithHttpInfo(order_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * import
     * @param {String} http_x_auth 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ManagerApiV1ImportsCreateSuccessResponse} and HTTP response
     */
    createImportWithHttpInfo(http_x_auth) {
      
      let postBody = null;
      // verify the required parameter 'http_x_auth' is set
      if (http_x_auth === undefined || http_x_auth === null) {
        throw new Error("Missing the required parameter 'http_x_auth' when calling createImport");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'http-x-auth': http_x_auth
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['multipart/form-data'];
      let accepts = ['application/json'];
      let returnType = ManagerApiV1ImportsCreateSuccessResponse;

      return this.apiClient.callApi(
        '/manager/api/v1/imports', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * import
     * @param {<&vendorExtensions.x-jsdoc-type>} http_x_auth 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ManagerApiV1ImportsCreateSuccessResponse}
     */
    createImport(http_x_auth) {
      return this.createImportWithHttpInfo(http_x_auth)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_category
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Category} and HTTP response
     */
    fetchCategoryWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchCategory");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Category;

      return this.apiClient.callApi(
        '/catalog/v1/categories/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_category
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Category}
     */
    fetchCategory(id, opts) {
      return this.fetchCategoryWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch category banners
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchCategoryBannersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchCategoryBanners");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/catalog/v1/categories/{id}/banners', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch category banners
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchCategoryBanners(id, opts) {
      return this.fetchCategoryBannersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch client config
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ClientConfig} and HTTP response
     */
    fetchClientConfigWithHttpInfo() {
      
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ClientConfig;

      return this.apiClient.callApi(
        '/shopit/v1/client_config.json', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch client config
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ClientConfig}
     */
    fetchClientConfig() {
      return this.fetchClientConfigWithHttpInfo()
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * orders
     * @param {String} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Orders} and HTTP response
     */
    fetchOpenOrdersWithHttpInfo(user_id) {
      
      let postBody = null;
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling fetchOpenOrders");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Orders;

      return this.apiClient.callApi(
        '/api/v1/users/{user_id}/orders/incomplete', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * orders
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Orders}
     */
    fetchOpenOrders(user_id) {
      return this.fetchOpenOrdersWithHttpInfo(user_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_order
     * @param {String} order_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    fetchOrderWithHttpInfo(order_id) {
      
      let postBody = null;
      // verify the required parameter 'order_id' is set
      if (order_id === undefined || order_id === null) {
        throw new Error("Missing the required parameter 'order_id' when calling fetchOrder");
      }

      let pathParams = {
        'order_id': order_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/api/v1/orders/{order_id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_order
     * @param {<&vendorExtensions.x-jsdoc-type>} order_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    fetchOrder(order_id) {
      return this.fetchOrderWithHttpInfo(order_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_order_offers
     * @param {String} order_id uuid
     * @param {Object} opts Optional parameters
     * @param {Array} opts.offer_ids 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchOrderOffersWithHttpInfo(order_id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'order_id' is set
      if (order_id === undefined || order_id === null) {
        throw new Error("Missing the required parameter 'order_id' when calling fetchOrderOffers");
      }

      let pathParams = {
        'order_id': order_id
      };
      let queryParams = {
        'offer_ids': opts['offer_ids']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/api/v1/orders/{order_id}/offers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_order_offers
     * @param {<&vendorExtensions.x-jsdoc-type>} order_id uuid
     * @param {Object} opts Optional parameters
     * @param {Array} opts.offer_ids 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchOrderOffers(order_id, opts) {
      return this.fetchOrderOffersWithHttpInfo(order_id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * orders
     * @param {String} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.per_page number of items per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrdersIndex} and HTTP response
     */
    fetchOrdersWithHttpInfo(user_id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling fetchOrders");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        'page': opts['page'],'per_page': opts['per_page']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OrdersIndex;

      return this.apiClient.callApi(
        '/api/v1/users/{user_id}/orders', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * orders
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {Number} opts.page page number
     * @param {Number} opts.per_page number of items per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrdersIndex}
     */
    fetchOrders(user_id, opts) {
      return this.fetchOrdersWithHttpInfo(user_id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_product
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Products} and HTTP response
     */
    fetchProductWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchProduct");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Products;

      return this.apiClient.callApi(
        '/catalog/v1/products/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_product
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Products}
     */
    fetchProduct(id, opts) {
      return this.fetchProductWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_product
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductShowOffersJson} and HTTP response
     */
    fetchProductOffersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchProductOffers");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductShowOffersJson;

      return this.apiClient.callApi(
        '/catalog/v1/products/{id}/offers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_product
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductShowOffersJson}
     */
    fetchProductOffers(id, opts) {
      return this.fetchProductOffersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * root_categories
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RootCategories} and HTTP response
     */
    fetchRootCategoriesWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = RootCategories;

      return this.apiClient.callApi(
        '/catalog/v1/categories', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * root_categories
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RootCategories}
     */
    fetchRootCategories(opts) {
      return this.fetchRootCategoriesWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * sellable_offers
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchSellableOffersWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSellableOffers");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/catalog/v1/sellables/{id}/offers', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * sellable_offers
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchSellableOffers(id, opts) {
      return this.fetchSellableOffersWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_supplier_combos
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchSupplierCombosWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplierCombos");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/{id}/combos', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_supplier_combos
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchSupplierCombos(id) {
      return this.fetchSupplierCombosWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_leaflets
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Leaflets} and HTTP response
     */
    fetchSupplierLeafletsWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplierLeaflets");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Leaflets;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/{id}/leaflets', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_leaflets
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Leaflets}
     */
    fetchSupplierLeaflets(id) {
      return this.fetchSupplierLeafletsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_supplier_promotions
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchSupplierPromotionsWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplierPromotions");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/{id}/promotions', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_supplier_promotions
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchSupplierPromotions(id) {
      return this.fetchSupplierPromotionsWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_supplier_promotions_active
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/PromotionsActive} and HTTP response
     */
    fetchSupplierPromotionsActiveWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplierPromotionsActive");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = PromotionsActive;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/{id}/promotions_active', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_supplier_promotions_active
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/PromotionsActive}
     */
    fetchSupplierPromotionsActive(id) {
      return this.fetchSupplierPromotionsActiveWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_supplier_stokvel
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Offers} and HTTP response
     */
    fetchSupplierStokvelWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplierStokvel");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Offers;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/{id}/stokvel', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_supplier_stokvel
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Offers}
     */
    fetchSupplierStokvel(id) {
      return this.fetchSupplierStokvelWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_supplier_stokvel_active
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/StokvelActive} and HTTP response
     */
    fetchSupplierStokvelActiveWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplierStokvelActive");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = StokvelActive;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/{id}/stokvel_active', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_supplier_stokvel_active
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/StokvelActive}
     */
    fetchSupplierStokvelActive(id) {
      return this.fetchSupplierStokvelActiveWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_supplier_timeslots
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.requires_shipping requires_shipping
     * @param {Boolean} opts.stokvel stokvel
     * @param {String} opts.provider payment provider
     * @param {String} opts.shipping_provider shipping provider
     * @param {String} opts.address_id shipping address id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SupplierTimeslots} and HTTP response
     */
    fetchSupplierTimeslotsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchSupplierTimeslots");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        'requires_shipping': opts['requires_shipping'],'stokvel': opts['stokvel'],'provider': opts['provider'],'shipping_provider': opts['shipping_provider'],'address_id': opts['address_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = SupplierTimeslots;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/{id}/timeslots', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_supplier_timeslots
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {Boolean} opts.requires_shipping requires_shipping
     * @param {Boolean} opts.stokvel stokvel
     * @param {String} opts.provider payment provider
     * @param {String} opts.shipping_provider shipping provider
     * @param {String} opts.address_id shipping address id
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SupplierTimeslots}
     */
    fetchSupplierTimeslots(id, opts) {
      return this.fetchSupplierTimeslotsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * fetch_suppliers
     * @param {Object} opts Optional parameters
     * @param {String} opts.lat 
     * @param {String} opts.lon 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Suppliers} and HTTP response
     */
    fetchSuppliersWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'lat': opts['lat'],'lon': opts['lon']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Suppliers;

      return this.apiClient.callApi(
        '/catalog/v1/suppliers/distance_from', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * fetch_suppliers
     * @param {Object} opts Optional parameters
     * @param {String} opts.lat 
     * @param {String} opts.lon 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Suppliers}
     */
    fetchSuppliers(opts) {
      return this.fetchSuppliersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * transaction
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Transaction} and HTTP response
     */
    fetchTransactionWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling fetchTransaction");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Transaction;

      return this.apiClient.callApi(
        '/api/v1/transactions/{id}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * transaction
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Transaction}
     */
    fetchTransaction(id) {
      return this.fetchTransactionWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * addresses
     * @param {String} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Addresses} and HTTP response
     */
    fetchUserAddressesWithHttpInfo(user_id) {
      
      let postBody = null;
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling fetchUserAddresses");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Addresses;

      return this.apiClient.callApi(
        '/api/v1/users/{user_id}/addresses', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * addresses
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Addresses}
     */
    fetchUserAddresses(user_id) {
      return this.fetchUserAddressesWithHttpInfo(user_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * user forgot password
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ForgotPasswordResponse} and HTTP response
     */
    forgotPasswordWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling forgotPassword");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ForgotPasswordResponse;

      return this.apiClient.callApi(
        '/api/v1/account/{scope}/forgot_password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * user forgot password
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {String} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ForgotPasswordResponse}
     */
    forgotPassword(scope, opts) {
      return this.forgotPasswordWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * create
     * @param {String} http_x_auth 
     * @param {Object} opts Optional parameters
     * @param {module:model/V1LeafletsBody} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ManagerApiV1LeafletsCreateSuccessResponse} and HTTP response
     */
    managerApiV1LeafletsPostWithHttpInfo(http_x_auth, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'http_x_auth' is set
      if (http_x_auth === undefined || http_x_auth === null) {
        throw new Error("Missing the required parameter 'http_x_auth' when calling managerApiV1LeafletsPost");
      }

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        'http-x-auth': http_x_auth
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ManagerApiV1LeafletsCreateSuccessResponse;

      return this.apiClient.callApi(
        '/manager/api/v1/leaflets', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * create
     * @param {<&vendorExtensions.x-jsdoc-type>} http_x_auth 
     * @param {Object} opts Optional parameters
     * @param {module:model/V1LeafletsBody} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ManagerApiV1LeafletsCreateSuccessResponse}
     */
    managerApiV1LeafletsPost(http_x_auth, opts) {
      return this.managerApiV1LeafletsPostWithHttpInfo(http_x_auth, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * modify_order
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/OrderModification>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    modifyOrderWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling modifyOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/api/v1/orders/{id}/modify', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * modify_order
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/OrderModification>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    modifyOrder(id, opts) {
      return this.modifyOrderWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * creates a debtor registration
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/NewDebtorRegistrationPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/UserUpdateResponse} and HTTP response
     */
    newDebtorRegistrationWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling newDebtorRegistration");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = UserUpdateResponse;

      return this.apiClient.callApi(
        '/api/v1/users/{id}/new_debtor_card', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * creates a debtor registration
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/NewDebtorRegistrationPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/UserUpdateResponse}
     */
    newDebtorRegistration(id, opts) {
      return this.newDebtorRegistrationWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * new address
     * @param {String} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/Address} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CreateAddressResponse} and HTTP response
     */
    newUserAddressWithHttpInfo(user_id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling newUserAddress");
      }

      let pathParams = {
        'user_id': user_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CreateAddressResponse;

      return this.apiClient.callApi(
        '/api/v1/users/{user_id}/addresses', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * new address
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/Address} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CreateAddressResponse}
     */
    newUserAddress(user_id, opts) {
      return this.newUserAddressWithHttpInfo(user_id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * offline_payment
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    offlinePaymentWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling offlinePayment");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/api/v1/orders/{id}/offline_payment', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * offline_payment
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    offlinePayment(id) {
      return this.offlinePaymentWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * prepare_order
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    prepareOrderWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling prepareOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/api/v1/orders/{id}/prepare', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * prepare_order
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    prepareOrder(id) {
      return this.prepareOrderWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * processing
     * @param {String} order_id 
     * @param {String} transaction_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    processingWithHttpInfo(order_id, transaction_id) {
      
      let postBody = null;
      // verify the required parameter 'order_id' is set
      if (order_id === undefined || order_id === null) {
        throw new Error("Missing the required parameter 'order_id' when calling processing");
      }
      // verify the required parameter 'transaction_id' is set
      if (transaction_id === undefined || transaction_id === null) {
        throw new Error("Missing the required parameter 'transaction_id' when calling processing");
      }

      let pathParams = {
        'order_id': order_id,'transaction_id': transaction_id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/api/v1/orders/{order_id}/transactions/{transaction_id}/processing', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * processing
     * @param {<&vendorExtensions.x-jsdoc-type>} order_id 
     * @param {<&vendorExtensions.x-jsdoc-type>} transaction_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    processing(order_id, transaction_id) {
      return this.processingWithHttpInfo(order_id, transaction_id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * register
     * @param {Object} opts Optional parameters
     * @param {module:model/RegisterOrderRequest} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/RegisterOrderResponse} and HTTP response
     */
    registerOrderWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = RegisterOrderResponse;

      return this.apiClient.callApi(
        '/api/v1/orders/register', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * register
     * @param {Object} opts Optional parameters
     * @param {module:model/RegisterOrderRequest} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/RegisterOrderResponse}
     */
    registerOrder(opts) {
      return this.registerOrderWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * register_transaction
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Transaction} and HTTP response
     */
    registerTransactionWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling registerTransaction");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Transaction;

      return this.apiClient.callApi(
        '/api/v1/orders/{id}/transactions', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * register_transaction
     * @param {<&vendorExtensions.x-jsdoc-type>} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Transaction}
     */
    registerTransaction(id) {
      return this.registerTransactionWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * delete address bridge
     * @param {String} user_id uuid
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Addresses} and HTTP response
     */
    removeUserAddressWithHttpInfo(user_id, id) {
      
      let postBody = null;
      // verify the required parameter 'user_id' is set
      if (user_id === undefined || user_id === null) {
        throw new Error("Missing the required parameter 'user_id' when calling removeUserAddress");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling removeUserAddress");
      }

      let pathParams = {
        'user_id': user_id,'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Addresses;

      return this.apiClient.callApi(
        '/api/v1/users/{user_id}/addresses/{id}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * delete address bridge
     * @param {<&vendorExtensions.x-jsdoc-type>} user_id uuid
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Addresses}
     */
    removeUserAddress(user_id, id) {
      return this.removeUserAddressWithHttpInfo(user_id, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * reset password
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ResetPasswordResponse} and HTTP response
     */
    resetPasswordWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling resetPassword");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ResetPasswordResponse;

      return this.apiClient.callApi(
        '/api/v1/account/{scope}/reset_password', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * reset password
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ResetPasswordPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ResetPasswordResponse}
     */
    resetPassword(scope, opts) {
      return this.resetPasswordWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * resolve_payment_pending
     * @param {String} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Order} and HTTP response
     */
    resolvePaymentPendingWithHttpInfo(id) {
      
      let postBody = null;
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling resolvePaymentPending");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = Order;

      return this.apiClient.callApi(
        '/api/v1/orders/{id}/resolve_payment_pending', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * resolve_payment_pending
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Order}
     */
    resolvePaymentPending(id) {
      return this.resolvePaymentPendingWithHttpInfo(id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * review_order
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/OrderReview} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/InlineResponse200} and HTTP response
     */
    reviewOrderWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling reviewOrder");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = InlineResponse200;

      return this.apiClient.callApi(
        '/api/v1/orders/{id}/review', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * review_order
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/OrderReview} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/InlineResponse200}
     */
    reviewOrder(id, opts) {
      return this.reviewOrderWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * sample_product
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductShowJson} and HTTP response
     */
    sampleProductWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'supplier_id': opts['supplier_id']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = ProductShowJson;

      return this.apiClient.callApi(
        '/catalog/v1/products/sample', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * sample_product
     * @param {Object} opts Optional parameters
     * @param {String} opts.supplier_id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductShowJson}
     */
    sampleProduct(opts) {
      return this.sampleProductWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * offers
     * @param {Object} opts Optional parameters
     * @param {String} opts.query 
     * @param {String} opts.supplier_id uuid
     * @param {Number} opts.page page number
     * @param {Number} opts.per_page number of items per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OfferSearchResponse} and HTTP response
     */
    searchOffersWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = null;

      let pathParams = {
        
      };
      let queryParams = {
        'query': opts['query'],'supplier_id': opts['supplier_id'],'page': opts['page'],'per_page': opts['per_page']
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = [];
      let accepts = ['application/json'];
      let returnType = OfferSearchResponse;

      return this.apiClient.callApi(
        '/catalog/v1/offers/search', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * offers
     * @param {Object} opts Optional parameters
     * @param {String} opts.query 
     * @param {String} opts.supplier_id uuid
     * @param {Number} opts.page page number
     * @param {Number} opts.per_page number of items per page
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OfferSearchResponse}
     */
    searchOffers(opts) {
      return this.searchOffersWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * sync
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CartItem>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CartComparison} and HTTP response
     */
    shopitV1CompareSyncPostWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CartComparison;

      return this.apiClient.callApi(
        '/shopit/v1/compare/sync', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * sync
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/CartItem>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CartComparison}
     */
    shopitV1CompareSyncPost(opts) {
      return this.shopitV1CompareSyncPostWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * account sign in
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignInResponse} and HTTP response
     */
    signInWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling signIn");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SignInResponse;

      return this.apiClient.callApi(
        '/api/v1/account/{scope}/sign_in', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * account sign in
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignInResponse}
     */
    signIn(scope, opts) {
      return this.signInWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * account sign up
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignUpPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignUpResponse} and HTTP response
     */
    signUpWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling signUp");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SignUpResponse;

      return this.apiClient.callApi(
        '/api/v1/account/{scope}/sign_up', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * account sign up
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/SignUpPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignUpResponse}
     */
    signUp(scope, opts) {
      return this.signUpWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * updates a user
     * @param {String} scope 
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/SignInResponse} and HTTP response
     */
    updateAccountWithHttpInfo(scope, id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling updateAccount");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateAccount");
      }

      let pathParams = {
        'scope': scope,'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = SignInResponse;

      return this.apiClient.callApi(
        '/api/v1/account/{scope}/{id}/update', 'PATCH',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * updates a user
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {module:model/SignInPayload} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/SignInResponse}
     */
    updateAccount(scope, id, opts) {
      return this.updateAccountWithHttpInfo(scope, id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * update
     * @param {String} body 
     * @param {String} http_x_auth 
     * @param {String} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing HTTP response
     */
    updateImportWithHttpInfo(body, http_x_auth, id) {
      
      let postBody = body;
      // verify the required parameter 'body' is set
      if (body === undefined || body === null) {
        throw new Error("Missing the required parameter 'body' when calling updateImport");
      }
      // verify the required parameter 'http_x_auth' is set
      if (http_x_auth === undefined || http_x_auth === null) {
        throw new Error("Missing the required parameter 'http_x_auth' when calling updateImport");
      }
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling updateImport");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        'http-x-auth': http_x_auth
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = [];
      let returnType = null;

      return this.apiClient.callApi(
        '/manager/api/v1/imports/{id}', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * update
     * @param {<&vendorExtensions.x-jsdoc-type>} body 
     * @param {<&vendorExtensions.x-jsdoc-type>} http_x_auth 
     * @param {<&vendorExtensions.x-jsdoc-type>} id 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}
     */
    updateImport(body, http_x_auth, id) {
      return this.updateImportWithHttpInfo(body, http_x_auth, id)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * validate
     * @param {Object} opts Optional parameters
     * @param {module:model/CartValidationRequest} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CartValidationResponse} and HTTP response
     */
    validateWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = CartValidationResponse;

      return this.apiClient.callApi(
        '/api/v1/cart/validate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * validate
     * @param {Object} opts Optional parameters
     * @param {module:model/CartValidationRequest} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CartValidationResponse}
     */
    validate(opts) {
      return this.validateWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * validate
     * @param {Object} opts Optional parameters
     * @param {Array.<[Object]>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OffersValidationResponse} and HTTP response
     */
    validateOfferPicksWithHttpInfo(opts) {
      opts = opts || {};
      let postBody = opts['body'];

      let pathParams = {
        
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OffersValidationResponse;

      return this.apiClient.callApi(
        '/api/v1/offers/validate_picks', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * validate
     * @param {Object} opts Optional parameters
     * @param {Array.<[Object]>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OffersValidationResponse}
     */
    validateOfferPicks(opts) {
      return this.validateOfferPicksWithHttpInfo(opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * validate_order_modifications
     * @param {String} id uuid
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/OrderModification>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/OrderModificationsValidationResponse} and HTTP response
     */
    validateOrderModificationsWithHttpInfo(id, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'id' is set
      if (id === undefined || id === null) {
        throw new Error("Missing the required parameter 'id' when calling validateOrderModifications");
      }

      let pathParams = {
        'id': id
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = OrderModificationsValidationResponse;

      return this.apiClient.callApi(
        '/api/v1/orders/{id}/validate_modifications', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * validate_order_modifications
     * @param {<&vendorExtensions.x-jsdoc-type>} id uuid
     * @param {Object} opts Optional parameters
     * @param {Array.<module:model/OrderModification>} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/OrderModificationsValidationResponse}
     */
    validateOrderModifications(id, opts) {
      return this.validateOrderModificationsWithHttpInfo(id, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * validate password reset token
     * @param {String} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ScopeValidateTokenBody} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ValidatePasswordResetToken} and HTTP response
     */
    validatePasswordResetTokenWithHttpInfo(scope, opts) {
      opts = opts || {};
      let postBody = opts['body'];
      // verify the required parameter 'scope' is set
      if (scope === undefined || scope === null) {
        throw new Error("Missing the required parameter 'scope' when calling validatePasswordResetToken");
      }

      let pathParams = {
        'scope': scope
      };
      let queryParams = {
        
      };
      let headerParams = {
        
      };
      let formParams = {
        
      };

      let authNames = [];
      let contentTypes = ['application/json'];
      let accepts = ['application/json'];
      let returnType = ValidatePasswordResetToken;

      return this.apiClient.callApi(
        '/api/v1/account/{scope}/validate_token', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType
      );
    }

    /**
     * validate password reset token
     * @param {<&vendorExtensions.x-jsdoc-type>} scope 
     * @param {Object} opts Optional parameters
     * @param {module:model/ScopeValidateTokenBody} opts.body 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ValidatePasswordResetToken}
     */
    validatePasswordResetToken(scope, opts) {
      return this.validatePasswordResetTokenWithHttpInfo(scope, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }

}