import { api_for } from '@/services/helper'

import _ from 'underscore'

default_state = -> {
  all: []
  current: null
  error: null
  loading: false
}

state = default_state()

getters = {
  with_cart_qty: (state, g, rs, root_getters) ->
    offers = _.sortBy(state.all, 'price_in_cents')
    offers = (Object.assign(offer, { qty: root_getters['cart/offer_qty'](offer.id) }) for offer in offers)
    offers

  supplier_offer: (state, g, rs, root_getters) ->
    state.all.find (offer) -> offer.supplier.id == root_getters['suppliers/current'].id
}

actions = {
  fetch_for: ({ commit, rootGetters, rootState }, sellable_id) ->
    commit('loading')
    api_for(rootState.application, rootGetters['suppliers/current'])
    .fetchSellableOffers sellable_id
    .then (offers) ->
      if rootGetters['suppliers/current']
        offers = offers.filter (offer) -> offer.supplier.id == rootGetters['suppliers/current'].id
      commit('set_offers', offers)
    .catch (response) ->
      commit('set_offers', [])
      commit('error', 'not_found')
    .finally -> commit('loaded')

  search: ({ commit, rootGetters, rootState }, params) ->
    supplier = rootGetters['suppliers/current']
    api_for(rootState.application, supplier)
      .searchOffers { query: params.q, supplier_id: supplier.id, page: params.page }
      .then (response) -> response

  fetch_supplier_promotions: ({ commit, rootState, rootGetters }) ->
    commit('loading')
    api_for(rootState.application, rootGetters['suppliers/current'])
    .fetchSupplierPromotions rootGetters['suppliers/current'].id
    .then (offers) -> commit('set_offers', offers)
    .catch (response) -> commit('error', 'not_found')
    .finally -> commit('loaded')

  promotions_active: ({rootState, rootGetters}) ->
    supplier = rootGetters['suppliers/current']
    api_for(rootState.application, supplier)
    .fetchSupplierPromotionsActive(supplier.id)
    .then (active) -> active
    .catch (response) -> console.error response

  stokvel_active: ({rootState, rootGetters}) ->
    supplier = rootGetters['suppliers/current']
    api_for(rootState.application, supplier)
    .fetchSupplierStokvelActive(supplier.id)
    .then (active) -> active
    .catch (response) -> console.error response

  fetch_supplier_stokvel: ({ commit, rootState, rootGetters }) ->
    commit('loading')
    api_for(rootState.application, rootGetters['suppliers/current'])
    .fetchSupplierStokvel rootGetters['suppliers/current'].id
    .then (offers) -> commit('set_offers', offers)
    .catch (response) -> commit('error', 'not_found')
    .finally -> commit('loaded')

  fetch_supplier_combos: ({ commit, rootState, rootGetters }) ->
    commit('loading')
    api_for(rootState.application, rootGetters['suppliers/current'])
    .fetchSupplierCombos rootGetters['suppliers/current'].id
    .then (offers) -> commit('set_offers', offers)
    .catch (response) -> commit('error', 'not_found')
    .finally -> commit('loaded')

}

mutations = {
  loaded: (state) ->
    state.loading = false

  loading: (state) ->
    state.loading = true

  set_current: (state, offer) ->
    state.current = offer

  set_current_from_id: (state, id) ->
    state.current = state.all.find (offer) -> offer.id == id

  adding_to_cart: (state, offer) ->
    state.current = offer

  set_offers: (state, offers) ->
    state.all = offers

  reset: (state) ->
    Object.assign(state, default_state())
}

export default {
  namespaced: true
  state
  getters
  actions
  mutations
}
